.chardinjs-overlay {
  position: absolute;
  z-index: 999999;
  background-color: #000;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }

.chardinjs-helper-layer {
  position: absolute;
  z-index: 9999998;
  color: white;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }
  .chardinjs-helper-layer.chardinjs-left {
    border-left: solid white 1px;
    margin-left: -10px; }
  .chardinjs-helper-layer.chardinjs-right {
    border-right: solid white 1px;
    padding-right: 10px; }
  .chardinjs-helper-layer.chardinjs-bottom {
    border-bottom: solid white 1px;
    padding-bottom: 10px; }
  .chardinjs-helper-layer.chardinjs-top {
    border-top: solid white 1px;
    padding-top: 10px; }

.chardinjs-tooltip {
  position: absolute;
  -webkit-transition: opacity 0.1s ease-out;
  -moz-transition: opacity 0.1s ease-out;
  -ms-transition: opacity 0.1s ease-out;
  -o-transition: opacity 0.1s ease-out;
  transition: opacity 0.1s ease-out;
  max-width: 200px; }
  .chardinjs-tooltip.chardinjs-left {
    margin-left: -135px;
    padding-right: 10px; }
  .chardinjs-tooltip.chardinjs-right {
    margin-right: -135px;
    padding-left: 10px; }
  .chardinjs-tooltip.chardinjs-bottom {
    margin-bottom: -50px;
    padding-top: 10px; }
  .chardinjs-tooltip.chardinjs-top {
    margin-top: -50px;
    padding-bottom: 10px; }
  .chardinjs-tooltip.chardinjs-right:before, .chardinjs-tooltip.chardinjs-left:after, .chardinjs-tooltip.chardinjs-bottom:before, .chardinjs-tooltip.chardinjs-top:after {
    content: ".";
    display: inline-block;
    background-color: white;
    height: 1px;
    overflow: hidden;
    position: absolute; }
  .chardinjs-tooltip.chardinjs-right:before, .chardinjs-tooltip.chardinjs-left:after {
    width: 100px;
    top: 50%; }
  .chardinjs-tooltip.chardinjs-bottom:before, .chardinjs-tooltip.chardinjs-top:after {
    width: 1px;
    height: 50px;
    left: 50%; }
  .chardinjs-tooltip.chardinjs-bottom:before {
    top: -50px; }
  .chardinjs-tooltip.chardinjs-top:after {
    bottom: -50px; }
  .chardinjs-tooltip.chardinjs-right:before {
    left: -100px; }
  .chardinjs-tooltip.chardinjs-left:after {
    right: -100px; }

.chardinjs-show-element {
  z-index: 9999999;
  opacity: 0.8; }

.chardinjs-relative-position {
  position: relative; }